<template>
  <div @click.self="$emit('show', false)" class="fixed flex justify-center items-center z-50 bg-opacity-70 bg-white h-full w-full left-0 p-4 sm:p-12 md:p-0 cursor-pointer">
    <div class="relative w-full md:w-1/2 border border-black bg-primary p-4 cursor-default">
      <div @click="$emit('show', false)" class="absolute flex justify-center items-center -right-2 -top-2 bg-primary border border-black rounded-full w-6 h-6 cursor-pointer">
        <img class="w-full p-1" src="@/assets/svg/cross-black.svg"/>
      </div>
      <div>
        <text-block :data="settings.pop_up.content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  components: {
    TextBlock: () => import('@/components/blocks/TextBlock.vue'),
  },
  computed: {
    settings() {
      return this.$store.getters.settings
    }
  }
}
</script>

<style>

</style>